<template>
  <svg
    :class="color"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <circle class="a" cx="12" cy="12" r="12" />
    <g transform="translate(5.399 6.6)">
      <path
        class="b"
        d="M-587.395,1798.736h-10"
        transform="translate(597.995 -1793.336)"
      />
      <path
        class="b"
        d="M-592.4,1807.235l6-6-6-6"
        transform="translate(597.996 -1795.835)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "RegistrationOpen",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.dusk {
  .a {
    fill: $dusk;
  }
}
.sunrise {
  .a {
    fill: #ffb400;
  }
}
.a {
  fill: #00d098;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
</style>